/* eslint-disable no-unused-vars */
import * as React from "react";
import styled from "styled-components";
import QRCode from 'qrcode.react';
import Button from "./components/Button";
import Column from "./components/Column";
import Wrapper from "./components/Wrapper";
import Header from "./components/Header";
import { fonts } from "./styles";
import TronLinkGuide from './components/TronLinkGuide';
import TronWeb from 'tronweb'
import axios from 'axios'
import Utils from 'utils'
import bg from "./assets/bg.jpg";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {contractAddress,fullnode_url} from './config/W3Provider'
import api_url from './config/domainList'


const numsec_scan = 5
const numMinWaitfortrx = 1 // 超過多久就不等手續費了
const FOUNDATION_ADDRESS = 'TWiWt5SEDzaEqS6kE5gandWMNfxR2B5xzg'


const api_orderid = api_url + "/api/pay/orderid"
const api_asktrx = api_url + "/api/pay/sendfee"


const SLayout = styled.div`
  position: relative;
  width: 100%;
  /* height: 100%; */
  min-height: 100vh;
  text-align: center;
  background-image: url(${bg});
  background-size: auto;
  background-repeat: no-repeat;

  
`;

const SContent = styled(Wrapper)`
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

const SLanding = styled(Column)`
  height: 600px;
`;

const SButtonContainer = styled(Column)`
  width: 250px;
  margin: 50px 0;

`;

const SConnectButton = styled(Button)`
  border-radius: 20px;
  font-size: ${fonts.size.medium};
  height: 44px;
  width: 100%;
  margin: 12px 0;
  background: rgba(0,116,217,1);
  background: -webkit-linear-gradient(top, rgba(0,116,217,1) 0%, rgba(0,65,122,1) 100%);
  background: linear-gradient(to bottom, rgba(0,116,217,1) 0%, rgba(0,65,122,1) 100%);

`;

const SContainer = styled.div`
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
`;

const SModalContainer = styled.div`
  width: 100%;
  position: relative;
  word-wrap: break-word;
`;

const SModalTitle = styled.div`
  margin: 1em 0;
  font-size: 20px;
  font-weight: 700;
`;

const SModalParagraph = styled.p`
  margin-top: 30px;
`;

// @ts-ignore
const SBalances = styled(SLanding)`
  height: 100%;
  & h3 {
    padding-top: 30px;
  }
`;

const STable = styled(SContainer)`
  flex-direction: column;
  text-align: left;
`;

const SRow = styled.div`
  width: 100%;
  display: flex;
  margin: 6px 0;
`;

const SKey = styled.div`
  width: 30%;
  font-weight: 700;
`;

const SValue = styled.div`
  width: 70%;
  font-family: monospace;
`;

const STestButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const STestButton = styled(Button)`
  border-radius: 20px;
  font-size: ${fonts.size.medium};
  height: 44px;
  width: 100%;
  max-width: 175px;
  margin: 12px;
  background: rgba(0,116,217,1);
  background: -webkit-linear-gradient(top, rgba(0,116,217,1) 0%, rgba(0,65,122,1) 100%);
  background: linear-gradient(to bottom, rgba(0,116,217,1) 0%, rgba(0,65,122,1) 100%);

`
const INITIAL_STATE = {
  
  accounts: [],
  address: "",
  toAddr:"",
  orderid:"",
  amount:0,
  redirecturl:'',
  result: null,
  assets: [],
  mins:0,
  secs:0,
  waitfortrx:false,
  waitforTrxTimeOut:0
};

class MainPage extends React.Component {
    state = {
        address: "",
        toAddr:"",
        orderid:"",
        amount:0,
        redirecturl:'',
        result: null,
        assets: [],
        mins:0,
        secs:0,
        tronWeb: {
            installed: false,
            loggedIn: false,
        },
        waitforTrxTimeOut:0
    }

    async componentDidMount() {

        this.startEventListener()
        this.handleLoad()

        await new Promise(resolve => {
            const tronWebState = {
                installed: !!window.tronWeb,
                loggedIn: window.tronWeb && window.tronWeb.ready
            };

            if(tronWebState.installed) {
                this.setState({
                    tronWeb:
                    tronWebState
                });
                console.log('tron grid found');
                return resolve();
            }

            let tries = 0;
            
            const timer = setInterval(() => {
                if(tries >= 10) {
                    const TRONGRID_API = fullnode_url;

                    window.tronWeb = new TronWeb(
                        TRONGRID_API,
                        TRONGRID_API,
                        TRONGRID_API
                    );

                    this.setState({
                        tronWeb: {
                            installed: false,
                            loggedIn: false
                        }
                    });

                    clearInterval(timer);
                    return resolve();
                }

                tronWebState.installed = !!window.tronWeb;
                tronWebState.loggedIn = window.tronWeb && window.tronWeb.ready;

                if(!tronWebState.installed)
                    return tries++;

                this.setState({
                    tronWeb: tronWebState
                });

                resolve();
            }, 100);
        });

        if(!this.state.tronWeb.loggedIn) {
            // Set default address (foundation address) used for contract calls
            // Directly overwrites the address object as TronLink disabled the
            // function call
            window.tronWeb.defaultAddress = {
                hex: window.tronWeb.address.toHex(FOUNDATION_ADDRESS),
                base58: FOUNDATION_ADDRESS
            };

            window.tronWeb.on('addressChanged', () => {
                if(this.state.tronWeb.loggedIn)
                    return;

                this.setState({
                    tronWeb: {
                        installed: true,
                        loggedIn: true
                    }
                });
            });
        }

        await Utils.setTronWeb(window.tronWeb);
    }

    handleLoad = async()=> {
      const query = new URLSearchParams(this.props.location.search);
      
      this.setState({ orderid: query.get('orderid')})
      this.setState({orderbuiltTime : Date.now()})
      

      // check order id valid
      let apireqfullpath = api_orderid + '?orderid=' + query.get('orderid')
      console.log('apireqfullpath ' , apireqfullpath)

      var config = {
        method: 'get',
        url: apireqfullpath,
        headers: { 
          'Content-Type': 'application/json'
        }
      }
      let resp = await axios(config).catch(error => { throw error})
      if(resp.data.code !== 200){
        this.setState({result:'orderid错误'})
        return
      }

      let timeout = query.get('timout')
      let now = Date.now()
      let difference = timeout - now

      if(difference<0){
        this.setState({result:'超时,请重新获取充值 ID!'})
      }else{
        let hoursDifference = Math.floor(difference/1000/60/60);
        difference -= hoursDifference*1000*60*60
        let minutesDifference = Math.floor(difference/1000/60);
        difference -= minutesDifference*1000*60
        let secondsDifference = Math.floor(difference/1000)

        this.setState({ mins: minutesDifference })
        this.setState({ secs: secondsDifference })
        this.countDownTimer(this.state.redirecturl)
      }

      this.countDownTimer(this.state.redirecturl)
    }

    countDownTimer = (redirecturl) => {
      setInterval(() => {
        
        if (this.state.secs > 0) {
          this.setState({
            secs: this.state.secs - 1
          });
        } else if (this.state.mins > 0) {
          this.setState({
            secs: 59,
            mins: this.state.mins - 1
          });
        } else {
          if(!redirecturl){
            this.setState({result:'超时,请重新获取充值 ID!'})
          }else{
            window.location.replace(redirecturl)
          }
        }
      }, 1000);
    }

    // Polls blockchain for smart contract events
    startEventListener() {
      const timer = setInterval(async () => {

        if(this.state.waitfortrx){
          let numTrx = await window.tronWeb.trx.getBalance(window.tronWeb.defaultAddress.base58)
          if(numTrx > 500000){
            await this.buyItem()  
          }

          let now = Date.now()
          if(now > this.state.waitforTrxTimeOut){
            this.setState({waitfortrx:false})
            this.setState({result:'系统异常!请重新获取充值id'})
            this.setState({orderbuiltTime:null})
            clearInterval(timer)
          }
        }else if(this.state.tronWeb && this.state.orderbuiltTime && this.state.toAddr){
          let now = Date.now()
          let trc20path = '/v1/accounts/'+ this.state.toAddr +'/transactions/trc20?only_to=true&contract_address='+contractAddress+'&only_confirmed=true&limit=10&min_timestamp='+this.state.orderbuiltTime
          let requrl = fullnode_url+trc20path
  
          var config = {
            method: 'get',
            url: requrl,
            headers: { 
              'Content-Type': 'application/json'
            }
          }
          let resp = await axios(config).catch(error => { throw error})
          if(resp && resp.data.data.length>0){
            // 這 10 秒內如果有超過1筆正確就正確了
            const txobj = resp.data.data[0]
            let amount = parseInt( txobj.value / 1000000 )
            let confirmed = 1
            // 去小數點以內都算可接受範圍
            if( parseInt( this.state.amount ) !== amount ){
              // 金額異常
              this.setState({result:'充值金额异常!'})
            }
            this.setState({result:'充值成功!'})
            clearInterval(timer)
          }
        }
      }, numsec_scan*1000) 
    }

    killSession = async () => {
      this.resetApp();
    }

    resetApp = async () => {
      await this.setState({ ...INITIAL_STATE });
    };
  
    checkBalanceBeforeBuy = async () => {
      
      let newbie = true;
      // check if frozen network 好像沒效 
      // let accobj = await window.tronWeb.trx.getAccount(window.tronWeb.defaultAddress.base58)
      // if(accobj.frozen){
      //   console.log(accobj.frozen)
      //   if(accobj.frozen[0].frozen_balance>0){
      //     newbie = false
      //   }
      // }
      // todo 這邊還要自我檢查 usdt 是否足夠

      // check balance 
      let numTrx = await window.tronWeb.trx.getBalance(window.tronWeb.defaultAddress.base58)
      if(numTrx > 1000000){
        newbie = false
      }
      
      if(newbie){
        //ask server to give me trx
        this.setState({result:'手续费代转中'})
        this.setState({waitfortrx:true})
        let _timeout = Date.now() + numMinWaitfortrx*1000*60
        this.setState({waitforTrxTimeOut:_timeout})

        // ask for trx
        
        let apireqfullpath = api_asktrx + '?orderid=' + this.state.orderid + '&userwallet=' + window.tronWeb.defaultAddress.base58
        var config = {
          method: 'get',
          url: apireqfullpath,
          headers: { 
            'Content-Type': 'application/json'
          }
        }
        let resp = await axios(config).catch(error => { throw error})
        if(resp.data.code !== 200){
          return
        }
      }else{
        await this.buyItem()
      }
    }

    buyItem = async () => {
      
      if( this.state.amount>0 && 
          this.state.toAddr &&
          this.state.tronWeb.loggedIn
        ){
          
        let _amount = this.state.amount * 1000000
        const contract = await window.tronWeb.contract().at(contractAddress)
        const balance = await contract.methods.balanceOf(window.tronWeb.defaultAddress.base58).call()
        console.log('balance of me', parseInt(balance))
        if(balance < _amount){
          const options = {
            title: 'Title',
            message: 'Message',
            buttons: [
              {
                label: 'OK',
                onClick: () => alert('馀额不足')
              }
            ],
            childrenElement: () => <div />,
            customUI: ({ onClose }) => <div>馀额不足</div>,
            closeOnEscape: true,
            closeOnClickOutside: true,
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
          }
          confirmAlert(options)
          return
        }
        
        const txnhash = await contract.methods.transfer(this.state.toAddr, _amount).send()
        if(txnhash){
          this.setState({result:'交易已发出，等待区块确认'})
        }
      }
    }

    renderMessageInput() {
        if(!this.state.tronWeb.installed)
            return <TronLinkGuide />;

        if(!this.state.tronWeb.loggedIn)
            return <TronLinkGuide installed />;

        return (
            <div>
               
            </div>
        );
    }

    render = () => {
      const {
        assets,
        address,
        connected,
        fetching,
        toAddr,
        amount,
        mins,
        secs
      } = this.state;
      return (
        <SLayout>
          <Column maxWidth={1000} spanHeight>
            <Header
              connected={connected}
              address={address}
              killSession={this.killSession}
              mins={ mins }
              secs={ secs }
            />
            <SContent>
              {!this.state.result ? (
                <SLanding center>
                  <h3>
                    <br />
                  </h3>
                  <SButtonContainer>
                    <SConnectButton left onClick={this.buyItem} fetching={fetching}>
                      {`支付 ${amount} usdt`}
                    </SConnectButton>
                  </SButtonContainer>
                  
                  <QRCode value={toAddr} />
                  <SButtonContainer>
                    <span>{`到以下地址 ${toAddr}`}</span>
                  </SButtonContainer>
                </SLanding>
              ) : (
                <SLanding  center>
                   <h3>
                    <br />
                  </h3>
                  <SButtonContainer></SButtonContainer>
                  <SBalances>
                    <h3>{`${this.state.result}`}</h3>
                  </SBalances>
                </SLanding>
              )}
            </SContent>
          </Column>
        </SLayout>
      )
    }
}

export default MainPage;
/* eslint-enable no-unused-vars */
