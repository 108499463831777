const TESTNET = process.env

var fullnode_url = "https://api.trongrid.io"
// usdt tron
var contractAddress = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t" 

if(TESTNET){
  fullnode_url = "https://api.shasta.trongrid.io" 
  contractAddress = "TJs8UQnvsyTY5kqRLnBk4kV39FSM81P16a"
}

module.exports = {contractAddress,fullnode_url}
