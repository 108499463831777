import * as React from "react";
import {BrowserRouter,Route} from 'react-router-dom';
import MainPage from "./MainPage";

class App extends React.Component{

    render = () => {
    return (
        <BrowserRouter>
            <Route path="/" component={MainPage}/>
        </BrowserRouter>
        )
    }
}

export default App;
